import React from "react";
import countryImage from "../../../assets/countryLocation.svg";

const Location = () => {
  return (
    <div className="w-full mt-[20px] ">
      <img src={countryImage} alt="" />
    </div>
  );
};

export default Location;
