import Work from "../../assets/work.svg";
import Migrate from "../../assets/migrate.svg";
import Leisure from "../../assets/leisure.svg";
import Investment from "../../assets/investment.svg";
import Family from "../../assets/family.svg";
import Enterprenue from "../../assets/enterprenue.svg";
import Techindustries from "../../assets/tech_industries.svg";
import Religion from "../../assets/religion.svg";
import Studyexcha from "../../assets/studyexcha.svg";
import Victim from "../../assets/victim_support.svg";
import Startupvent from "../../assets/startup_vent.svg";
import Others from "../../assets/others.svg";
import Study from "../../assets/study.svg";

export const PurposeLogo = [
  {
    name: "work",
    logo: Work,
  },
  {
    name: "study",
    logo: Study,
  },
  {
    name: "migrate",
    logo: Migrate,
  },
  {
    name: "leisure",
    logo: Leisure,
  },
  {
    name: "investment",
    logo: Investment,
  },
  {
    name: "family",
    logo: Family,
  },
  {
    name: "enterprenue",
    logo: Enterprenue,
  },
  {
    name: "techindustries",
    logo: Techindustries,
  },
  {
    name: "religion",
    logo: Religion,
  },
  {
    name: "studyexcha",
    logo: Studyexcha,
  },
  {
    name: "victimsupport",
    logo: Victim,
  },
  {
    name: "startupvent",
    logo: Startupvent,
  },
  {
    name: "others",
    logo: Others,
  },
];
