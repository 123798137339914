import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import axios from "axios";
import Assisthero from "../../assets/services/assisthero.svg";
import Locationimg from "../../assets/services/locationimg.svg";
import Circle from "../../assets/services/circle.svg";
import CircleBlue from "../../assets/services/circleblue.svg";
import cornerright from "../../assets/services/cornerright.svg";
import arrowup from "../../assets/services/arrowup.svg";
import ReactFlagsSelect from "react-flags-select";
import Services from "../../LegalTranscription/Home/home/services";
import Location from "../../LegalTranscription/Home/home/location";
import dasarrow from "../../assets/dasarrow.svg";
import study from "../../assets/study.svg";
import studymobile from "../../assets/studymobile.svg";
import desharrowmed from "../../assets/desharrowmed.svg";
import dasboardmobile from "../../assets/dasboardmobile.svg";
import gradientimg from "../../assets/gradientimg.svg";
import gradientimgm from "../../assets/gradientimgm.svg";
import icon from "../../assets/chevron-up.svg";
import Seo from "components/seo";
import Layout from "components/Layout";
import { CountryArray } from "core/utils/countryArray";
import { PurposeLogo } from "core/utils/purposeLogo";
import { Link } from "gatsby";

const Translate = (props) => {
  const pageData = props.pageContext.data._source;

  const {
    register,
    control,

    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loader, setLoader] = useState(false);
  const [response, setResponse] = useState("");

  const [activeSectionIndex, setActiveSectionIndex] = useState(0);

  useEffect(() => {
    anchorLayout();
    document.addEventListener("scroll", anchorScroll);
    return () => {
      document.removeEventListener("scroll", anchorScroll);
    };
  });

  const anchorScroll = () => {
    if (document.getElementsByClassName("layout-anchor").length > 0) {
      const navHeight = document.querySelector(".sticky")?.offsetHeight;
      const scrollPos =
        document.documentElement.scrollTop || document.body.scrollTop;
      const ulList = document.querySelectorAll(
        ".anchor-navigation ul > li > a"
      );
      Array.prototype.forEach.call(ulList, function (el) {
        const index = el.getAttribute("index");
        const refElement = document?.getElementById(index);
        const currLink = el;
        const elemPos = refElement?.offsetTop - navHeight;
        if (
          currLink.classList.contains("customAnchor")
            ? scrollPos >= refElement?.offsetTop
            : scrollPos >= elemPos
        ) {
          Array.prototype.forEach.call(ulList, function (el2) {
            el2.classList?.remove("active");
          });
          el.classList?.add("active");
          setActiveSectionIndex(Number(index));
        }
      });
    }
  };

  const anchorLayout = () => {
    if (document.getElementsByClassName("layout-anchor").length > 0) {
      var navHeight = document.getElementsByClassName("sticky")[0].offsetHeight;

      var ulList = document.querySelectorAll(".anchor-navigation ul > li > a");
      Array.prototype.forEach.call(ulList, function (el, i) {
        el.addEventListener(
          "click",
          function () {
            // Array.prototype.forEach.call(ulList, function(el2, i){
            //   el2.classList.remove("active")
            // });
            var index = el?.getAttribute("index");
            const refElement = document.getElementById(index)?.offsetTop - 150;

            window.scroll({
              top: refElement,
              behavior: "smooth",
            });

            // el.classList.add("active")
          },
          false
        );
      });
    }
  };

  const sidenavItems = [
    "Documents for Translation",
    "regulatory_guidelines",
    "Translator Qualifications",
    "Certification Requirements",
  ];
  // const getSpecificText = (starting) => {
  //   if (starting.includes("South Korea")) {
  //     return "South Korea ";
  //   } else if (starting.includes("Persian Gulf")) {
  //     return "Iran ";
  //   } else if (starting.includes("Congo")) {
  //     return "Congo ";
  //   } else {
  //     return ` ${starting.charAt(0).toUpperCase() + starting.slice(1)} `;
  //   }
  // };

  const purpose = PurposeLogo?.find(
    (item) => item.name === pageData?.info?.travel.toLocaleLowerCase()
  );

  const sourceCountry = CountryArray?.find(
    (item) =>
      item.name.toLocaleLowerCase() ===
      pageData?.info?.starting.toLocaleLowerCase()
  );

  const targetCountry = CountryArray?.find(
    (item) =>
      item.name.toLocaleLowerCase() ===
      pageData?.info?.destination.toLocaleLowerCase()
  );

  const sourceCountryFlag = sourceCountry?.flag;
  const targetCountryFlag = targetCountry?.flag;
  const purposeicon = purpose?.logo;

  return (
    <Layout>
      <Seo
        title={pageData?.meta?.meta_title}
        description={pageData?.meta?.meta_description}
        slug={pageData?.slug?.replace("/", "")}
      />
      <div className="w-full bior">
        <div className="w-11/12 lg:flex mt-[100px] gap-6 lg:items-start items-center justify-between max-w-[1240px] mx-auto text-[#0a1612] pt-[5rem] 500px:px-6">
          <div className="flex-col justify-center items-center w-full">
            <h1 className="text-5xl font-bold leading-[72px] font-primary text-center">
              {pageData?.h1_text}
            </h1>

            <div
              className="text-lg font-normal leading-7 font-primary text-center"
              dangerouslySetInnerHTML={{ __html: pageData?.short_description }}
            ></div>
          </div>
        </div>
        <div className="sm:px-4 px-2 back items-center flex justify-center">
          <div className="flex    items-center  ">
            <div className="border-2 border-[#7dcbff] h-max rounded-full flex gap-2 items-center sm:p-2 p-[2px]">
              <h1 className="border-2 border-[#7dcbff] rounded-full bg-white md:px-6 p-[4px] flex sm:gap-2 gap-[4px] w-max items-center">
                <img src={sourceCountryFlag} className="md:w-[60px] w-[20px]" />
                <p className="lg:text-2xl md:text-base text-[12px] font-bold text-[#00173a]  ">
                  {pageData?.info?.starting.charAt(0).toUpperCase() +
                    pageData?.info?.starting.slice(1)}
                </p>
              </h1>
            </div>

            <div className="flex items-center justify-center  ">
              <img src={dasarrow} alt="dasarrow" className="lg:block hidden" />

              <img
                src={dasboardmobile}
                alt="dasharroe"
                className="lg:hidden block sm:w-[30px] w-[10px] "
              />
              <div className="border-2 border-[#7dcbff] rounded-full md:w-[85px] sm:w-[60px] w-[45px] flex items-center p-2 md:p-4 ">
                <img src={purposeicon} alt="study" className="" />
              </div>

              <img src={dasarrow} alt="dasarrow" className="lg:block hidden " />

              <img
                src={dasboardmobile}
                alt="dasharroe"
                className="lg:hidden block sm:w-[30px] w-[10px]"
              />
            </div>
            <div className="border-2 border-[#7dcbff] h-max w-max rounded-full flex gap-2 items-center sm:p-2 p-[2px]">
              <h1 className="border-2 border-[#7dcbff] rounded-full bg-white md:px-6 p-[4px] flex sm:gap-2 gap-[4px] w-max items-center">
                <img src={targetCountryFlag} className="md:w-[60px] w-[20px]" />
                <p className="lg:text-2xl md:text-base text-[12px]  font-bold text-[#00173a] ">
                  {pageData?.info?.destination.charAt(0).toUpperCase() +
                    pageData?.info?.destination.slice(1)}
                </p>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className=" w-full bg-[#F8FBFF] pb-10">
        <div className="w-11/12 mx-auto parent-div-scroll bg-cover bg-no-repeat pt-12 flex-layout layout-anchor">
          <div className="lg:flex gap-6 lg:items-start justify-between max-w-7xl mx-auto pb-6 text-black">
            <div className="w-1/2 sticky top-40 lg:block hidden lg:px-6 md:px-16 px-4">
              <div
                id="scrollableElementId"
                className="fixedElement anchor-navigation"
              >
                <ul>
                  {sidenavItems.map(
                    (key, index) =>
                      key !== "regulatory_guidelines" && (
                        <li key={index}>
                          <a
                            index={index}
                            className={
                              index === activeSectionIndex
                                ? "customAnchor active scrollTo" + index
                                : "scrollTo" + index
                            }
                          >
                            <p className="text-[#5C636D] leading-6 text-[15px] font-sans ">
                              {key}
                            </p>
                          </a>
                        </li>
                      )
                  )}
                </ul>
              </div>
            </div>
            <div
              className="lg:w-1/2 w-full lg:px-10 md:px-16 500px:px-4"
              id="anchorTagsColor "
            >
              {Object.entries(pageData.data).map(
                ([key, value], index) =>
                  key !== "regulatory_guidelines" && (
                    <div
                      className={`mb-12 p-4 bg-white ${
                        index === activeSectionIndex
                          ? "border border-[#00173a] rounded-lg"
                          : ""
                      }`}
                      id={index}
                      key={index}
                      style={{
                        boxShadow: "0px 8px 34px 0px rgba(0, 0, 0, 0.10)",
                      }}
                    >
                      <h2 className="text-base font-semibold  text-[#042c7f]  scroll-mt-20 md:w-full sticky 2xl:w-max-w-max">
                        {key === "documents_required"
                          ? `Documents Required for ${
                              pageData?.info?.starting.charAt(0).toUpperCase() +
                              pageData?.info?.starting.slice(1)
                            } `
                          : key
                              .split("_")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                      </h2>

                      <div className="text-[16px] font-primary text-[#0a2641] list_disc_css leading-loose pt-4 w-full md:w-full">
                        {Array.isArray(value) ? (
                          <ul>
                            {value.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}{" "}
                          </ul>
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{ __html: value }}
                          ></div>
                        )}
                      </div>
                    </div>
                  )
              )}

              <div className="">
                <p className="text-[#c7c7c7] text-sm font-normal font-opensans text-center">
                  This is Powered by OpenAI's ChatGPT 4.0.
                </p>
                <p className="text-[#c7c7c7] text-sm font-normal font-opensans text-center">
                  We may display inaccurate or offensive content that doesn't
                  represent Tomedes' views.{" "}
                </p>
              </div>
              <div className="mt-8 flex flex-col gap-2 items-center justify-center w-52 mx-auto">
                <button
                  type="button"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  className="text-[#00173a] font-opensans font-bold text-sm flex flex-col items-center"
                >
                  <img src={icon} alt="icon" />
                  <span className="mt-2 font-bold font-opensans text-sm">
                    Back to Top
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="rounded-t-2xl rounded-b-3xl h-full p-6 max-w-7xl  mx-auto lg:flex justify-around lg:gap-0 gap-10 items-center lg:bg-[url('../assets/gradientimg.svg')] md:bg-[url('../assets/gradientimgm.svg')] bg-[url('../assets/gradientimgmob.svg')] bg-cover bg-no-repeat bg-center">
            <div className="lg:w-[40%] ">
              <h1 className=" text-[#0a1612] text-4xl font-bold leading-[65px] custom-text-center-lg">
                Ready to{" "}
                <span className="text-[#007ed2]">
                  <span className="text-[#007ed2]">
                    {pageData?.info?.travel}
                  </span>
                </span>
                ?
              </h1>
              <p className="mt-4 text-lg font-normal leading-7 text-[#0a2641] custom-text-center-lg">
                This guide provides a general overview, and for specific,
                accurate, and detailed advice, consulting directly with the
                relevant institutions or migration experts is essential. For
                more detailed information, contact the{" "}
                <a
                  href={pageData?.embassy_link}
                  target="_blank"
                  className="underline "
                >
                  {pageData?.anchor_text}.
                </a>
              </p>
            </div>
            <div className="lg:w-[40%] lg:mt-0 mt-4 flex items-center justify-center">
              <Link
                to="/quote"
                className="bg-[#fccd6a] text-[#624d20] rounded-lg py-2 px-10 flex items-center justify-center"
              >
                Start here →
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Translate;
